import {  useState,useEffect } from "react";
import "./journal.css";
import axios from "axios";
import Loader from "../../components/loader/Loader";

export default function Journal() {
    const MinImgDir = "https://riga.flammarion.eu:5001/gallery/miniature/";
    const OriImgDir = "https://riga.flammarion.eu:5001/gallery/original/";
    const [postList, setPostList] = useState([]);
    useEffect(() => {
        const fetchPosts = async () => {
            let loader = document.getElementById("loader");
            const res = await axios.get("https://riga.flammarion.eu:5001/api/getposts");
            console.log(res);
            setPostList(res.data);
            console.log(res.data);
            loader.style.display = "none";
        };
        fetchPosts();
    }, []);
    return (
        <div className="journal">
            <Loader/>
                {postList.map((val, key)=> {
                    let urlImg = val.blog_photo;
                    return (
                        <div className={"Post"} key={key}>
                            <h1>{val.blog_title}</h1>
                            <a href={"/" + val.blog_label} className={"BlogLabel"}>{val.blog_label}</a>
                            <p>{val.blog_text}</p>
                            <div className={"blogImageContainer"}>
                                <a href={OriImgDir + urlImg}>
                                    {val.blog_photo && <img className="postImg" src={MinImgDir + val.blog_photo} alt=""/>}
                                </a>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}

