import Sidebar from "../../components/sidebar/Sidebar";
import "./home.css";
import homeImage from '../../assets/images/riga.jpg'
import {useEffect, useState} from "react";
import axios from "axios";
import { createBrowserHistory } from 'history'



export default function Home() {
    const [lastPost, setLastPost] = useState([]);
    const history = createBrowserHistory();
    useEffect(() => {
        const fetchPosts = async () => {
            const res = await axios.get("https://riga.flammarion.eu:5001/api/getposts");
            setLastPost(res.data[0]);
            console.log(res.data[0]);
        };
        fetchPosts();
    }, []);

    function GoToJournalPage(){
        history.push('/journal');
        history.go(0)
    }


  return (
      <div className="home">
          <div className={"home-content"}>
              <div className={"home-image-slider"}>
                  <img src={homeImage} className={'homeImage'}/>
                  <h1>Mon Voyage</h1>
              </div>
          <div className={"home-presentation"}>
          <h2>Prémices :</h2>
              <div className={"home-presentation-paragraph"}>
        <p><strong>Bienvenue</strong> sur la présentation de mon voyage à Riga.
            Il va vous faire vivre à travers ce site mes expériences, découvertes et rencontres.</p>
              </div>
          </div>
          <div className={"home-preview"}>
              <h2>Dernières nouvelles</h2>
                <div className={"home-preview-content"} onClick={GoToJournalPage }>
                        <h1>{lastPost.blog_title}</h1>
                        <p>{lastPost.blog_text}</p>
                </div>
          </div>
          </div>
          <Sidebar/>
      </div>
  );
}
