import { Link } from "react-router-dom";
import "./sidebar.css";

export default function Sidebar() {
  return (
    <div className="sidebar">
        {/*Ohlalall relou, seul le texte des tites sont cliquable, il faut juste mettre les liens autour des titres, et revoir un peu le css*/}
        <div className={"sidebar-content"}>
      <div className="sidebarItem">
        <span className="sidebarTitleNav">
            NAVIGATION
        </span>
        <p>
          Vous pouvez choisir où aller
        </p>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">
            <Link className="sidebar-link" to="/trajet">
              TRAJET
            </Link>
        </span>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">
            <Link className="sidebar-link" to="/culture">
              CULTURE
            </Link>
        </span>
      </div>
      <div className="sidebarItem">
          <span className="sidebarTitle">
            <Link className="sidebar-link" to="/cadre-de-vie">
              CADRE DE VIE
            </Link>
          </span>
      </div>
      <div className="sidebarItem">
          <span className="sidebarTitle">
            <Link className="sidebar-link" to="/sport">
              SPORT
            </Link>
          </span>
      </div>
      <div className="sidebarItem">
          <span className="sidebarTitle">
            <Link className="sidebar-link" to="/cuisine">
              CUISINE
            </Link>
          </span>
      </div>
      <div className="sidebarItem">
          <span className="sidebarTitle">
            <Link className="sidebar-link" to="/autre">
              AUTRE
            </Link>
          </span>
      </div>
    </div>
    </div>
  );
}
