import React, {useEffect, useState} from 'react';
import './album.css'
import axios from "axios";
import {createBrowserHistory} from "history";
import Loader from "../../components/loader/Loader";

function Album() {
    const history = createBrowserHistory();
    const MinImgDir = "https://riga.flammarion.eu:5001/gallery/miniature/";
    const OriImgDir = "https://riga.flammarion.eu:5001/gallery/original/";
    const [postList, setPostList] = useState([]);
    useEffect(() => {
        const fetchPosts = async () => {
            let loader = document.getElementById("loader");
            const res = await axios.get("https://riga.flammarion.eu:5001/api/getposts");
            console.log(res);
            setPostList(res.data);
            console.log(res.data);
            loader.style.display = "none";
        };
        fetchPosts();
    }, []);


    return (
        <div className="album">
            <h3>Vous pouvez cliquer sur une image pour l'agrandir</h3>
            <Loader/>
                {postList.map((val, key)=> {
                    let urlImg = val.blog_photo;
                    if (val.blog_photo !== ""){
                        return (
                            <div className={"AllImagesContainer"} key={key} onClick={() => history.push(OriImgDir + urlImg)}>
                                {val.blog_photo && <img className="AlbumImg" src={MinImgDir + urlImg} alt=""/>}
                            </div>
                        );
                    }
                    else {}
                })}
        </div>
    );
}

export default Album;