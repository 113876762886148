import "./footer.css";
import {Link} from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
        <p>Voyage de Paul Flammarion à Riga en 2022</p>
</div>
);
}
