import { useContext, useState,useEffect } from "react";
import "./write.css";
import axios from "axios";


//import { Dropdown, Selection } from 'react-dropdown-now';
//import 'react-dropdown-now/style.css';


import React, { Component } from 'react'
import Select from 'react-select'
import Loader from "../../components/loader/Loader";



export default function Write() {
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [file, setFile] = useState(null);
  const [photo, setphoto]=useState("");
  const [mdpClient, setMdpClient] = useState("");
  const [label, setLabel] = useState("");


  const handleSubmit = async (e) => {
    let writeLoader = document.getElementById("writeLoader");
    writeLoader.style.visibility = "visible";
    e.preventDefault();

    const newPost = {
      title,
      desc,
      photo,
      mdpClient,
      label
    };

    if (file) {
      const data =new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      newPost.photo = filename;
      try {
        await axios.post("https://riga.flammarion.eu:5001/api/upload", data);
      } catch (err) {}
    }
    try {
      const res = await axios.post("https://riga.flammarion.eu:5001/api/posts", newPost);
      console.log(res.data);
      writeLoader.style.visibility = "hidden";
      await PostPosted();
    } catch (err) {
      writeLoader.style.visibility = "hidden";
      console.log(err.message);
      await PostError();
    }
  };

  async function PostPosted() {
    const val = document.getElementById("writePopupSuccess");
    document.getElementById("writeForm").reset();
    val.style.visibility = "visible";
    await delay(5000);
    val.style.visibility = "hidden";
  }
  async function PostError() {
    const val = document.getElementById("writePopupError");
    val.style.visibility = "visible";
    await delay(5000)
    val.style.visibility = "hidden";

  }

  return (
    <div className="write">
      <div id={"writeLoader"}>
        <h1>Loading...</h1>
      </div>
      <div id={"writePopupSuccess"}>
        <h1>Votre poste a été réalisé avec succès</h1>
      </div>
      <div id={"writePopupError"}>
        <h1>Une erreur est survenue, veuillez vérifier votre contenu</h1>
      </div>
      {file && (
        <img className="writeImg" src={URL.createObjectURL(file)} alt="" />
      )}
      <form className="writeForm" onSubmit={handleSubmit} id={'writeForm'}>
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <input
              type="text"
              placeholder="Key"
              className="keyInput"
              autoFocus={true}
              onChange={e=>setMdpClient(e.target.value)}
          />
          <select
              onChange={e=>setLabel(e.target.value)}
          >
            <option value="">Veuillez choisir un label</option>
            <option value="trajet">Trajet</option>
            <option value="culture">Culture</option>
            <option value="cadre-de-vie">Cadre de Vie</option>
            <option value="sport">Sport</option>
            <option value="cuisine">Cuisine</option>
            <option value={"autre"}>Autre</option>
          </select>
          <input
            type="text"
            placeholder="Title"
            className="writeInput"
            autoFocus={true}
            onChange={e=>setTitle(e.target.value)}
          />
          
        </div>
        <div className="writeFormGroup">
          <textarea
            placeholder="Tell your story..."
            type="text"
            className="writeInput writeText"
            onChange={e=>setDesc(e.target.value)}
          ></textarea>
        </div>
        <button className="writeSubmit" type="submit">
          Publish
        </button>
      </form>
    </div>
  );
}
