import { useContext } from "react";
import { Link } from "react-router-dom";
import "./topbar.css";

export default function TopBar() {

  const PF = "http://localhost:5000/images/";

  return (
    <div className="top">
      <div className="topLeft">
      </div>
      <div className="topCenter">
        <ul className="topList">
          <li className="topListItem">
            <Link className="link" to="/">
              ACCUEIL
            </Link>
          </li>
          <li className="topListItem">
            <Link className="link" to="/journal">
              JOURNAL
            </Link>
          </li>
          <li className="topListItem">
            <Link className="link" to="/album">
              ALBUM
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
