import Home from "./pages/home/Home";
import TopBar from "./components/topbar/TopBar";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Journal from "./pages/journal/Journal";
import Write from "./pages/write/Write";
import Album from "./pages/album/Album";
import Trajet from "./pages/slideBarPages/Trajet";
import Autre from "./pages/slideBarPages/Autre";
import CadreDeVie from "./pages/slideBarPages/CadreDeVie";
import Culture from "./pages/slideBarPages/Culture";
import Cuisine from "./pages/slideBarPages/Cuisine";
import Sport from "./pages/slideBarPages/Sport";
import NotFound from "./pages/error/NotFound";
import Redirect from "react-router-dom/es/Redirect";

function App() {
  return (
    <Router>
      <TopBar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path={'/journal'}><Journal/></Route>
        <Route path={"/write"}><Write/></Route>
        <Route path={"/album"}><Album/></Route>
        <Route path={"/trajet"}><Trajet/></Route>
        <Route path={"/autre"}><Autre/></Route>
        <Route path={"/cadre-de-vie"}><CadreDeVie/></Route>
        <Route path={"/culture"}><Culture/></Route>
        <Route path={"/cuisine"}><Cuisine/></Route>
        <Route path={"/sport"}><Sport/></Route>
        <Route path={"/404"}><NotFound/></Route>
        <Redirect path={"*"} to='/404' />
      </Switch>
        <Footer/>
    </Router>
  );
}

export default App;
